import React from "react";
import Layout from "../../../layouts";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/fintech/fintechbanner.jpg";
import ContrastSection from "../../../components/ContrastSection";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
} from "reactstrap";

//Partner
import logoISTQB from "../../../images/services/qa/istqb-platinum.png";
import Partner from "../../../images/services/fintech/fintech.png";

//Icons
import iconGrowth from "../../../images/services/fintech/icon/1growth.svg";
import iconTechnique from "../../../images/services/fintech/icon/2technique.svg";
import iconPuzzle from "../../../images/services/fintech/icon/3puzzle.svg";
import iconDevelopment from "../../../images/services/fintech/icon/4development.svg";
import iconRocket from "../../../images/services/fintech/icon/5rocket.svg";
import iconTarget from "../../../images/services/fintech/icon/6target.svg";
import iconTrustnew from "../../../images/services/fintech/icon/trustnew.svg";
import iconExperience from "../../../images/services/fintech/icon/experience.svg";
import iconTools from "../../../images/services/fintech/icon/tools.svg";
import iconDev from "../../../images/services/fintech/icon/dev.svg";
import iconTrust from "../../../images/services/fintech/icon/trust.svg";
import iconCost from "../../../images/services/qa/icon/cost-effective.svg";
import iconRisk from "../../../images/services/qa/icon/risk.svg";

//Clients
import Whodepemdonus from "../../../components/WhoDependsOnUs";
import Stride from '../../../images/services/logo/stride.png';
import Oxygen from '../../../images/services/logo/oxygen.png';
import Pokitpal from '../../../images/services/logo/pokitpal.png';
import Effi from '../../../images/services/logo/effi-logo.png';
import Possibl from '../../../images/home-page/logo/possibl-logo.png';

//Testimonial
import TabcmpSet from "../../../components/tab/TabcmpSet";
import ClientTestimonial3 from "../../../components/ClientTestimonialGaryPokitpal";
import GaryCobain from "../../../images/Clients/GaryCobain.jpeg";

//Casestudy
import downloadFile from '../../../documents/Case-Study-Customised-Software-Development.pdf'
import caseStudyImg from "../../../images/Enterprise-Integration.jpg";

//Contact
import SvgIconsSet from "../../../components/SvgIconsSet";
import HelpSection from "../../../components/Help";
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import UserCard from "../../../components/UserCard";
import Rashan from "../../../images/services/fintech/Rashan.png";
import Sylvia from "../../../images/services/qa/sylvia.jpeg";

const servicesList = [
  {
    id: "1",
    title: "Discovery workshops",
    textbold: "Need support to define the MVP scope to disrupt the market?",
    textdetail:
      "iTelaSoft's multidisciplinary engagement team brings together Architect, User experience and Design, Business Analyst and Cloud Solutioning capabilities to discover what you want to achieve in the short term and long term and define the scope. It is important to identify the other products in the market and find the niche with our innovative solutions. Outcome would be highlevel tech design, wireframes and a cost estimate for different scenarios.",
  },
  {
    id: "2",
    title: "Technology stack and team selection ",
    textbold: "Having the wireframes and figma designs but need to select the right technology to build?",
    textdetail:
      "At this stage your product is defined to a greater extend but selecting the right technology, tools and the necessary skills are crucial to make this stage a success. Close to 50% of the fintech firms are finding it hard to find the right software and engineering talents to build and continue their innovative platforms. iTelasoft will approach this stage with multiple choices depending on the go to market timelines, budgets and other limitations to assemble the right team. It is important to select the resources who have domain knowledge, right skills and cultural fit.",
  },
  {
    id: "3",
    title: "Integrations team ",
    textbold: "Product is developed in beta stage, progressing slowly but need to add integrations faster?",
    textdetail:
      "Most of the fintechs are working in the B2B sector as the traditional finance and non-financial businesses seeking to bolster their service offerings and improve their customer experience. One influencing trend is the rise in embedded finance, which can be defined as the integration of financial services or products within a non-financial services company’s value chain to form a seamless and interconnected customer experience. iTelasoft team can assess the options for various types of integrations, workarounds to meet the expected outcomes in a faster timeframe. By partnering with us, you can tap into our well experienced integrations team who is well knowledgeable in KYC integrations, Payment platform integrations, CRM integrations and many other systems integrations.",
  },
  {
    id: "4",
    title: "IP and Security",
    textbold: "Worried about how your IP is protected and data security in building the platform?",
    textdetail:
      "Security of IP is a major concern when working with a globally spread team as no one wants to end up having a same or similar product out there before yours. As a trusted partner, our integrated security approach will take care of all possible avenues of risks on sharing confidential product details, equipment and tools used and the people dealing with the project. iTelaSoft's commitment to compliance in consumer data protection act in Australia and GDPR in UK are key indicators that we take IP and security seriously.",
  },
  {
    id: "5",
    title: "Increasing runway",
    textbold: "Eligible for research & development grant in Australia?",
    textdetail:
      "The tougher capital raising environment creates difficulty around accessing lending and credit facilities which in turn impacts the ability to scale up and expand operations, or simply survive. Given that the fintech sector is inherently reliant on technological innovation, research and development remains an integral area of investment for fintech firms. Our matured onshore/offshore hybrid model could be the answer to increase your runway. Identifying the innovation aspect and recording them according to your accountant’s direction, our team will help your organisation throughout the journey.",
  },
  {
    id: "6",
    title: "API and Data management",
    textbold: "Securely monetise your data and services",
    textdetail:
      "Most FinTech’s are connected to their customers and other collaborating businesses through inbound and outbound data services; often implemented as APIs. Security of those services, metering, load handling & limiting, auditing, and maintain traceability are some of the important factors to pat attention in a FinTech business. iTelaSoft’s wide experience and partnerships in API management has been helping many FinTech businesses around the world to accomplish security, service quality and revenue.",
  },
];

const Fintech = () => {
  const tabData = [
    { label: "Gary Cobain", text:"PokitPal", image:GaryCobain, content: <ClientTestimonial3 /> },
  ];
  return (
    <Layout bodyClass="service-page fintech-service">
      <Seo
        title="Technology Services for Fintech"
        description="iTelaSoft is an Australian-owned company providing IT services and
              software product development.. We specialise in strategy
              consultation, design, development and management of products. We
              help entrepreneurs transform ground breaking ideas into successful
              solutions."
      />

      <IntroBanner
        title="Technology Services for Fintech"
        subtitle="Your Trusted Tech Partner for Success"
        image={Banner}
      />

      <div className="content">
        <ContrastSection
          title="Unlocking the Potential of Fintech Services"
          para="Fintech innovations are constantly reshaping financial industry at various scales from time to time, staying ahead means more than just keeping pace. They are confronted with the challenge of navigating evolving consumer and organisational behaviours. Partnering with a trusted tech ally is crucial for not only surviving but thriving in this competitive environment."
        />
      </div>
      <section className="section-our-partners">
        <Container className="industry-section">
          <Row className="mt-4 industry-partners">
            <Col>
              <div className="container pb-6 section-2">
                <div className="row">
                  <div className="col-md-6 pr-md-5">
										<img
												alt="istqb logo"
												className="img-fluid"
												src={Partner}
											/>
                  </div>
                  <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-end">
                    {/* <div className="title title-1">Industry Partners</div> */}
                    <p>
                    iTelaSoft is an ecosystem partner of FinTech Australia, which helps to grow and support the creation, development, and adoption of financial technology across the Australian economy.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="mt-0">
        {/* <Row className="Y-Salesforce mb-5">
            <Col sm={5} md={6} lg={4}>
              <div>
                <img
                  alt="salesforce logo"
                  className="img-fluid show"
                  src={Partner}
                />
              </div>
            </Col>
          </Row> */}
          <div className="title title-1">Why the right tech partner is important?</div>
          <div className="sub-title">
            Choosing a right tech partner such as iTelaSoft offers several advantages.{" "}
          </div>

          <Row className="mt-2">
            <Col md={6} lg={6}>
              <div class="icon-with-text-long">
                <img src={iconGrowth} alt="Icon"/>
                <div class="text">Deep understanding of the fintech industry to add strategic value to your operations and growth trajectory</div>
              </div>
              <div class="icon-with-text-long">
                <img src={iconTechnique} alt="Icon"/>
                <div class="text">Foster an environment that encourages innovation, enabling the exploration of novel ideas and solutions that propel your fintech venture forward</div>
              </div>
              <div class="icon-with-text-long">
                <img src={iconPuzzle} alt="Icon"/>
                <div class="text">The ability to adapt, upscale, or downsize your tech team according to evolving needs is crucial for sustained success</div>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div class="icon-with-text-long">
                <img src={iconDevelopment} alt="Icon"/>
                <div class="text">Access to a diverse pool of talent with the right expertise at different stages of product development </div>
              </div>
              <div class="icon-with-text-long">
                <img src={iconRocket} alt="Icon"/>
                <div class="text">Leveraging existing components and innovative approaches are paramount for staying ahead of the curve</div>
              </div>
              <div class="icon-with-text-long">
                <img src={iconTarget} alt="Icon"/>
                <div class="text">Look for a tech partner who shares your vision and is committed to growing alongside your fintech venture</div>
              </div>
            </Col>

            {/* <col>
              <div class="icon-with-text">
                <img src={iconExperience} alt="Icon"/>
                <div class="text">Unbiased and independent assessment</div>
              </div>
              <div class="icon-with-text">
                <img src={iconCompetency} alt="Icon"/>
                <div class="text">Access to specialised and experienced professionals with modern tooling & frameworks</div>
              </div>
              <div class="icon-with-text">
                <img src={iconTesting} alt="Icon"/>
                <div class="text">Flexibility, scalability & cost effectiveness</div>
              </div>
              <div class="icon-with-text">
                <img src={iconTesting} alt="Icon"/>
                <div class="text">Risk mitigation and regulatory compliance</div>
              </div>
            </col>  */}
            {/* <ul className="custom-list">
                <li>Unbiased and independent assessment{" "}</li>
                <li>Access to specialised and experienced professionals with modern tooling & frameworks{" "}</li>
                <li>Flexibility, scalability & cost effectiveness{" "}</li>
                <li>Risk mitigation and regulatory compliance{" "}</li>
            </ul> */}
          </Row>
        </Container>
      </section>
      <section>
        <Container className="service-container">
          <Row>
            <Col>
              <div>
                <div className="title title-1 mb-2">FinTech technology services</div>
                <div className="sub-title">
                As a valued ecosystem partner of Fintech Australia, iTelaSoft is committed to bridging the tech gap and addressing industry challenges with a people-centric approach. Leveraging cutting-edge tools and streamlined processes, we are poised to drive value creation in the fintech space. {" "}
                </div>
              </div>
              <div className="mt-4">
								{servicesList.map((service) => {
                  return (
                    <div className="wrapper-service" key={service.id}>
                      <div className="service-details">
                          <div className="service-title">{service.title}
                          </div>
                        <div className="service-content ml-2">
                          <div className="service-breif pb-1">
                            {service.textbold}
                          </div>
                          <div className="service-description">
                            {service.textdetail}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="container section-2">
                  <div className="row">
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Product Quality Engineering
                      </h2>
                      <p>
                        <strong>
                          Developing a new product that needs to be independently tested?
                        </strong>
                        <br />
                          A software platform is a live entity. Based on changes they go through, information they accumulate, people who access them, and vulnerabilities emerging, they evolve constantly. Corporates need to validate an existing platform from time to time to ensure it serves the right environment in the right way. iTelaSoft’s Platform Validation service offers an end-to-end assessment on usability, security, performance, vulnerabilities, and compliance. 
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Platform Validation
                      </h2>
                      <p>
                        <strong>
                          Need to validate your existing software platform?{" "}
                        </strong>
                        <br />
                        We'll work with you to create a high-quality website that matches
                        your needs, ensures ease of maintenance and maximises cost
                        efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">Product Modernisation Verification</h2>
                      <p>
                        <strong>Planning to modernise your legacy application?</strong>
                        <br />
                          Inevitably, software products also come to an end of life eventually; and may get modernised as a new product. Modernisation engagements are usually complex due to new tech, new frameworks, added capabilities and changed user experience, plus backward compatibility, existing integrations, and data migrations. We provide Product Modernisation Verification service, that starts with integrated planning, verification strategy and testing.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Managed Test Teams
                      </h2>
                      <p>
                        <strong>
                          Need qualified quality engineers to extend your team?{" "}
                        </strong>
                        <br />
                          Many organisations, especially medium to large scale enterprises often have their own product development team. In some cases, they have quality assurance and testing professionals as well. Despite in-house capacity, there are many reasons where external support is required. Those include, additional capacity needs, tooling and domain expertise, automation, accelerating time to market, and cost reduction. iTelaSoft provide Managed Test Teams to extend your team and perform software testing faster, better.
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <div className="wrap-case-study mt-3 pt-5">
        <Container>
          <div className="row pb-6">
            <div className="col-12">
              
              <div className="career">
                <div className="">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 flex-column">
                      <div>
                        <h5 className="title-5">Case Study</h5>
                        <h3 className="title-3">HOW THIS HOME LOAN PROVIDER GOT 60 LOAN ACQUISITIONS DAILY</h3>
                        <p>
                          A prominent home loan provider in Australia improved loan acquisition rates by 1,700% by integrating with a decisioning engine. Read how it was done.
                        </p>
                      </div>
                      <a href={downloadFile} download class="btn btn-primary btn-icon-button mt-2 mb-3">
                        <span className="btn-text">Download Case Study</span>
                        <span className="btn-icon">
                          <SvgIconsSet.SideArrow />
                        </span>
                      </a>
                    </div>
                    <div className="col-12 col-lg-6">
                      <img alt="product innovation" className="img-fluid" src={caseStudyImg} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container> 
      </div> */}
      <section>
        <Container>
          <div>
            <div className="title title-1 mb-2">Why iTelaSoft?</div>
            <div className="sub-title">
            Explore why startups, scale-ups, and large enterprises turn to iTelaSoft for top-notch Fintech Development Services, essential for navigating the dynamic landscape of financial technology.
            </div>
          </div>
          <Row className="mt-4">
          <Col md={6} lg={6}>
            <div class="icon-with-text">
              <img src={iconTechnique} alt="Icon"/>
              <div class="text">Thorough fintech industry knowledge </div>
            </div>
            <div class="icon-with-text">
              <img src={iconExperience} alt="Icon"/>
              <div class="text">Strong Tech and Support teams </div>
            </div>
            <div class="icon-with-text">
              <img src={iconTools} alt="Icon"/>
              <div class="text">Matured tools and processes </div>
            </div>
							{/* <ul className="custom-list-1">
									<li>Experience in complex applications and multiple domains{" "}</li>
									<li>Test competency in web, mobile, API, IoT, and integration applications.{" "}</li>
									<li>Specialised test needs such as cellular service testing & salesforce testing.{" "}</li>
							</ul> */}
					</Col>
					<Col md={6} lg={6}>
              <div class="icon-with-text">
                <img src={iconTrust} alt="Icon"/>
                <div class="text">15 Years of experience as a trusted partner </div>
              </div>
              <div class="icon-with-text">
                <img src={iconDev} alt="Icon"/>
                <div class="text">Peace of mind to focus on your business development </div>
              </div>
              <div class="icon-with-text">
                <img src={iconTrustnew} alt="Icon"/>
                <div class="text">Extensive experience in integrating Fintech services </div>
              </div>
					</Col>
          </Row>
          {/* <Row className="mt-4">
          <Col md={6} lg={6}>
							<ul className="custom-list-1">
									<li>Experience in complex applications and multiple domains{" "}</li>
									<li>Test competency in web, mobile, API, IoT, and integration applications.{" "}</li>
									<li>Specialised test needs such as cellular service testing & salesforce testing.{" "}</li>
							</ul>
						</Col>
						<Col md={6} lg={6}>
							<ul className="custom-list-2">
									<li>Mature methodology and tool-set.{" "}</li>
									<li>ISTQB Platinum Partner with certified engineers{" "}</li>
									<li>Compliance with industry standards (GDPR){" "}</li>
							</ul>
						</Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconExperience}
                  />
                  <CardTitle tag="h5">
                    Experience in complex applications and multiple domains
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconCompetency}
                  />
                  <CardTitle tag="h5">
                    Test competency in web, mobile, API, IoT, and integration
                    applications.
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconTesting}
                  />
                  <CardTitle tag="h5">
                    Specialised test needs such as cellular service testing &
                    salesforce testing.
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconTools}
                  />
                  <CardTitle tag="h5">
                    Mature methodology and tool-set.
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconCertified}
                  />
                  <CardTitle tag="h5">
                    ISTQB Platinum Partner with certified engineers
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconGdpr}
                  />
                  <CardTitle tag="h5">
                    Compliance with industry standards (GDPR)
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </section>
      <section className="section-our-partners">
        <Container>
          <Row>
            <Col>
            <div id="who-depend-on-us">
      {" "}
      <div className="title-1 mb-2">Who depends on us? </div>
      <div className="section-discription">
        Number of organisations ranging from start-ups, scale-ups, to large
        enterprises depend on iTelaSoft for Fintech services.
      </div>
      {/* <div className="img-grid">
          <div className="card">
                <a href="https://gigaclear.com/" target="_blank">
                  <img className="img-partner" alt="Partners" src={GigaClear} />
                </a>
          </div>
          <div className="card">
            <a href="https://oxygen.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Oxygen} />
            </a>
          </div>
          <div className="card">
            <a href="https://pokitpal.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={Pokitpal} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.effi.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Effi} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.beforepay.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Beforepay} />
            </a>
          </div>
          
        </div> */}
      <div className="section-our-partners-logo-set">
      <a href="https://www.pokitpal.com/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={Pokitpal} alt=""></img>
            </div>
            {/* <div className="logo-dec">PokitPal</div> */}
          </div>
        </a>
        <a href="https://www.strideequity.com.au/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={Stride} alt=""></img>
            </div>
            {/* <div className="logo-dec">Gigaclear</div> */}
          </div>
        </a>
        <a href="https://oxygen.com.au/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={Oxygen} alt=""></img>
            </div>
            {/* <div className="logo-dec">Oxygen</div> */}
          </div>
        </a>
        
        <a href="https://effi.com.au/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={Effi} alt=""></img>
            </div>
            {/* <div className="logo-dec">Effi</div> */}
          </div>
        </a>
        <a href="https://www.possibl.com.au/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={Possibl} alt=""></img>
            </div>
            {/* <div className="logo-dec">Beforepay</div> */}
          </div>
        </a>
      </div>
    </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="client-testimonial-section">
          <Container>  
            <div className="tab-header-fintech">
              <h3 className="title-1">What our client says</h3>
            </div>
            <TabcmpSet tabData={tabData} tabNum={1} />
          </Container>
      </section>
      
      {/* <section>
        <div className="content">
          <HelpSection
            title="Let Us Know How to Help"
            para="We love to hear what challenges you go through in your business and discuss how we can assist you."
          />
        </div>
      </section> */}
      <section className="section-contact">
        <Container>
          <Row>
                <Col md={9} lg={9} xl={9} className="title-section flex-column">
                  <div className="title title-1 desk">
                    Let us know how to help 
                  </div>
                  <div className="title title-1 mobile">
                    Let us know how to help 
                  </div>
                  <div>
                    <p>We love to hear what challenges you go through in your business and discuss how we can assist you.</p>
                  </div>
                  <div className="getting-touch-right">
                    <a className="btn btn-primary btn-icon-button" href="/contact/">
                        <span className="btn-text">Get in touch</span>
                        <span className="btn-icon">
                        <SvgIconsSet.SideArrow />
                        </span>
                    </a>
                  </div>
                </Col>
                <Col md={3} lg={3} xl={3} className="mb-2 contact-card-section col-6">
                    <a className="contact-card" href="/contact/">
                      <UserCard
                        name="Rashan Peiris"
                        detail="Fintech Domain Advisor"
                        image={Rashan}
                        link="https://www.linkedin.com/in/rashans/"
                      />
                    </a>
                </Col>
                {/* <Col md={6} lg={3} xl={3} className="mb-2">
                    <a className="contact-card" href="/contact/">
                      <UserCard
                        name="Sylvia Luo"
                        detail="Relationship Manager"
                        image={Sylvia}
                        link="https://www.linkedin.com/in/sylvia-luo-lol980609/"
                      />
                    </a>
                </Col> */}
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Fintech;
